const botAdminPages = [
  {
    label: 'Manage',
    to: { name: 'manage' },
    icon: 'wrench',
  },
  {
    label: 'Configuration',
    to: { name: 'config' },
    icon: 'cog',
  },
  {
    label: 'Advanced',
    to: { name: 'advanced' },
    icon: 'cubes',
  },
  {
    label: 'Audit Log',
    to: { name: 'audit-log' },
    icon: 'list',
  },
  {
    label: 'External',
    to: { name: 'external' },
    icon: 'diagram-project',
    platforms: ['zendesk-sunco', 'zendesk-sunco-broker'],
  },
];
const initialState = {
  showSidebar: true,
  pageLoading: true,
  allowSpeechSettings: false,
  allowSystemSettings: false,
};

const sidebarGetters = {
  pages: (state, getters, rootState, rootGetters) => botAdminPages.filter(
    (item) => (rootGetters['auth/isSuperuser'] ? true : item.linkName !== 'advanced'),
  ),
  speechSettingsEnabled: (state) => state.allowSpeechSettings,
};

const mutations = {
  setControlState(state, { newState }) {
    state.controlState = newState;
  },
  setShowSidebar(state, payload) {
    state.showSidebar = payload;
  },
  setAllowSystemSettings(state, payload) {
    state.allowSystemSettings = payload;
  },
  setAllowSpeechSettings(state, payload) {
    state.allowSpeechSettings = payload;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters: sidebarGetters,
  mutations,
};
